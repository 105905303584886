<template>
  <div>
    <CRow>
      <CCol sm="6">
        <CCard>
          <CCardHeader>
            <strong>Edit User </strong>
            <div class="card-header-actions">
              <a
                href="https://coreui.io/vue/docs/components/form-components"
                class="card-header-action"
                rel="noreferrer noopener"
                target="_blank"
              >
              </a>
              <CLink href="/#/users" class="card-header-action btn-close">
                <CIcon name="cil-x-circle"/>
              </CLink>
            </div>
          </CCardHeader>
          <CCardBody>
            <CForm>
              <h6>
                User Role:
              </h6>
              <select required v-model="selectedRole" class="role-dropdown" placeholder="Select Role">
                <option v-for="role in rolesList" :v-model="role.ID">{{ role.Name }}</option>
              </select>
                <template #prepend-content><CIcon name="cil-user"/></template>
              </CSelect>
              <CInput
                placeholder="Name"
                v-model="name"
                required
              >
                <template #prepend-content><CIcon name="cil-user"/></template>
              </CInput>
              <CInput
                placeholder="Email"
                type="email"
                v-model="email"
                required
                autocomplete="email"
                valid-feedback
                invalid-feedback="Please provide valid email"
                :is-valid="validateEmail"
              >
                <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
              </CInput>
              <div class="form-group form-actions">
                <CButton type="submit" @click="submit" color="success">Update</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import UserInfo from "./User.vue";
import { listRoles, updateUser, getUser } from "@/api/user.api.js";

export default {
  name: "EditUser",
  data() {
    return {
      id: null,
      email: null,
      name: null,
      selectedRole: {},
      rolesList: [],
      role_id: null,
    };
  },
  methods: {
    getUserDetails() {
      getUser(this.$route.params.id)
        .then((response) => {
          let user = response.data.data;
          this.id = user.id;
          this.name = user.name;
          this.email = user.email;
          this.selectedRole = user.role_id;
        })
        .catch((e) => {
          if (e.response.data.error) this.showToaster(e.response.data.error);
        });
    },

    getRoles() {
      listRoles().then((response) => {
        let roles = response.data.data;
        this.rolesList = roles;
      });
    },

    submit() {
      if (
        !this.validatePresence(this.name) ||
        !this.validateEmail(this.email)
      ) {
        this.showToaster("Please check the input");
        return;
      }

      updateUser(
        this.$route.params.id,
        this.email,
        this.name,
        this.selectedRole
      )
        .then((response) => {
          this.showSuccessToaster("Updated successfully");
          this.$router.push(`/users/${this.$route.params.id}`);
        })
        .catch((e) => {
          if (e.response.data.error) this.showToaster(e.response.data.error);
        });
    },

    validatePresence(val) {
      if (val == undefined) {
        return "";
      }
      return val ? val.length >= 1 : false;
    },

    validateEmail(val) {
      if (val == undefined) {
        return "";
      }
      var pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/;
      if (pattern.test(val)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getUserDetails();
    this.getRoles();
  },
};
</script>
